import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../Global.scss";
import Header from "../../../Components/Header/Header";
import HamburgerMenu from "../../../Components/HamburgerMenu/HamburgerMenu";
import { Single } from "../../../Components/CaseHistory/Single";
import Partnership from "../../../Components/Partnership/Partnership";
import Footer from "../../../Components/Footer/Footer";
import Seo from "../../../Components/Seo/Seo";

const ManfrottoCase = () => {
  return (
    <>
      <Seo locale="it" page="manfrotto" />
      <Header isDarkTheme={true} lang="it" />
      <HamburgerMenu />
      <Single customerName="Videndum" lang="it" />
      <Partnership lang="it" />
      <Footer lang="it" />
    </>
  );
};

export default ManfrottoCase;
